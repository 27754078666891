import React, { useEffect, useState } from "react";
import "../Blog/blog.css";
import about2 from "../../assets/about2.svg";
import { Link } from "react-router-dom";
import { saveToSessionStorage } from "../../utils/SessionHandler";
const Newsletter = () => {
  const [newsLetter, setNewsLetter] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  // Function to fetch blogs
  const fetchNewsData = async () => {
    try {
      const response = await fetch(
        "https://api.safekaro.com/api/news-letter",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const blogDetails = await response.json();
      setNewsLetter(blogDetails.data);
      saveToSessionStorage("news", blogDetails.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchNewsData();
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 3 >= newsLetter.length ? 0 : prevIndex + 3
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 3 < 0 ? newsLetter.length - 3 : prevIndex - 3
    );
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long", // Full month name (e.g., June)
      day: "numeric", // Day of the month (e.g., 1)
    };

    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const truncateDescription = (description, maxLength = 50) => {
    return description.length > maxLength
      ? description.slice(0, maxLength) + "..."
      : description;
  };

  const visibleBlogs = newsLetter.slice(currentIndex, currentIndex + 3);

  return (
    <section id="Services" className="services">
      <div className="services-provided">
        <div>
          {currentIndex > 0 && (
            <button className="prev" onClick={handlePrev}>
              &#10094;
            </button>
          )}

          <div className="blog-container">
            <h1 className="blog-title">
              Latest Insurance News{" "}
              <img src={about2} alt="about2" className="about2" />
            </h1>
            <div className="slider1">
              <div className="slider2">
                {visibleBlogs.map((news, index) => (
                  <div
                    key={index}
                    className={`service ${index === 1 ? "active" : ""}`}
                  >
                    <div className="image-container">
                      <img
                        src={`https://api.safekaro.com/uploads/${news.image}`}
                        alt={`Blog ${index + 1}`}
                      />
                      <div className="overlay"></div> {/* Black overlay */}
                      <div className="caption">
                        <p className="caption-date">{formatDate(news.date)}</p>
                        <h3 className="caption-title">{news.category}</h3>
                        <p className="caption-description">
                          {truncateDescription(news.title)}
                        </p>
                        <Link
                          to={`/news/${news._id}`}
                          className="read-more list-none "
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {currentIndex + 3 < newsLetter.length && (
            <button className="next" onClick={handleNext}>
              &#10095;
            </button>
          )}
        </div>
      </div>
    </section>
  );
};



export default Newsletter;
