import React from "react";
import logo from "../../assets/logo2.svg";
import "./footer.css";
import location from '../../assets/location.png'
import email from '../../assets/email.png'
import phone from '../../assets/phone.png'
import linkedin from '../../assets/linkedin.png'
import twitter from '../../assets/twitter.png'
import instagram from '../../assets/instagram.png'
import facebook from '../../assets/facebook.png'


const Footer = () => {
  return (
    <footer id="Footer">
      <div className="row">
        <div className="col">
          <img src={logo} alt="logo" className="footer-logo" />
            <p className="para">
              We are here to help you with all your insurance needs. Reach out
              us today to learn more about our service and how we can help
              protect what matter most to you.
            </p>
          <div className="social-icons">
            <img src={facebook} alt="facebook"/>
            <img src={twitter} alt="twitter"/>
            <img src={linkedin} alt="linkedin"/>
            <img src={instagram} alt="instagram"/>
          </div>
        </div>
        <div className="col">
          <h3>
            Service{" "}
            <div className="underline">
              <span></span>
            </div>
          </h3>
          <ul>
            <li>Health Insurance</li>
            <li>Car Insurance</li>
            <li>Home Insurance</li>
            <li>Life Insurance</li>
            <li>Business Insurance</li>
          </ul>
        </div>
        <div className="col">
          <h3>
            Quick Links{" "}
            <div className="underline">
              <span></span>
            </div>
          </h3>
          <ul>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/help">Help</a>
            </li>
            <li>
              <a href="/service">Service Request </a>
            </li>
            <li>
              <a href="/claims">Claims</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <h3>
            SafeKaro Head Office{" "}
            <div className="underline">
              <span></span>
            </div>{" "}
          </h3>
          <div>
            <ul className="footer-icons">
              <li className="footer-li-icons">
                <img src={location} alt="location"/>
                <p>
                  505,5th Floor, Royal World, Sansar Chandra Road, Jaipur -
                  302001
                </p>
              </li>
              <li className="footer-li-icons3">
              <img src={email} alt="email"/>
                <p>support@safekaro.com</p>
              </li>
              <li className="footer-li-icons3">
              <img src={phone} alt="phone"/>
                <p>0141 - 4806690</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <hr/>
      <p className="copyright"> ©️ 2024 SafeKaro. All rights reserved. Design by <a id="blueSparingLink" href="https://bluesparing.com" target="blank">Blue Sparing</a> with<span className="blinking-heart">❤️</span> </p>

    </footer>
  );
};

export default Footer;
