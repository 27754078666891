import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo2.svg";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-[#002C40] shadow-lg sticky z-10 top-0 w-full">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        {/* Logo */}
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img src={logo} alt="logo" className="w-[150px] h-auto" />
        </Link>
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden"
          aria-controls="navbar-default"
          aria-expanded={isOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        <div
          className={`${isOpen ? "block" : "hidden"} w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse">
            <li>
              <a
                href="#home"
                className="block px-3 text-white font-medium font_inter"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="block px-3 text-white font-medium font_inter"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="block px-3 text-white font-medium font_inter"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#blog"
                className="block px-3 text-white font-medium font_inter"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                href="#news"
                className="block px-3 text-white font-medium font_inter"
              >
                News
              </a>
            </li>
            <li>
              <a
                href="#claims"
                className="block px-3 text-white font-medium font_inter"
              >
                Claims
              </a>
            </li>
            <li className="bg-[#027aae] rounded-md p-1">
              <a
                href="https://admin.safekaro.com/"
                target="_blank"
                className="block px-3 text-white font-medium font_inter" rel="noreferrer"
              >
                Sign In
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
