import React from "react";
import "./about.css";
import img from "../../assets/about.svg";
import about2 from "../../assets/about2.svg";

const About = () => {
  return (
    <div className="about-main">
      <div className="about-content">
        {/*  Wrapper 1*/}
        <div className="wrapper-2">
          <div className="about-right-container" id='about-id'>
            <h3 className="about-title">
              About Us <img src={about2} alt="about2" className="about2" />
            </h3>
            <h2>
              Welcome to <span className="about-blue">Safe</span>
              <span className="about-orange">Karo</span>
            </h2>
            <p className="para1">
              At <span className="about-detail">SAFEKARO</span> we are dedicated
              to providing comprehensive and reliable insurance solutions
              tailored to meet the diverse needs of our clients. With 8 years of
              experience in the insurance industry, we have built a reputation
              for excellence and commitment to our customers. Our mission is to
              offer peace of mind to our clients by protecting what matters most
              to them. We strive to provide exceptional service, innovative
              insurance products, and a seamless claims process to ensure our
              clients feel secure and supported.
            </p>
            <div className="btn-1" id = 'btn-id'>
              <button className="read-btn">Read More</button>
            </div>
          </div>
        </div>
        {/* wrapper 2 */}
        <div className="wrapper-1">
          <div className="left-container">
            <img className="about-img" src={img} alt="about-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
