import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getFromSessionStorage } from "../../utils/SessionHandler";
import RelatedCard from "./RelatedCard";

const BlogDetails = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const [blog, setBlog] = useState(null);
  const [topFour, setTopFour] = useState([]);

  useEffect(() => {
    const data = getFromSessionStorage("blog");
    if (data) {
      const blogData = data.filter((ele) => ele._id === id);
      const topBlogs = data.filter((ele) => ele._id !== id).slice(0, 4);
      setBlog(blogData[0]);
      setTopFour(topBlogs);
    }
  }, [id]);

  if (!blog) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto mt-2">
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Main Blog Content */}
        <div className="lg:w-2/3">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <img
              src={`https://api.safekaro.com/uploads/${blog.image}`}
              alt={blog.title}
              className="w-full h-60 md:h-[30rem] object-cover"
            />
            <div className="p-6">
              <div className="my-1">
                <Link to="/" className="text-blue-500 hover:underline text-lg">
                  Back to Blogs
                </Link>
              </div>
              <h1 className="text-3xl font-bold mb-2">{blog.title}</h1>
              <p className="text-gray-500 text-sm mb-4">
                {new Date(blog.date).toLocaleDateString()} | By {blog.author}
              </p>
              <div
                className="blog-body text-gray-700"
                dangerouslySetInnerHTML={{ __html: blog.description }}
              />
            </div>
          </div>
        </div>

        {/* Related Blogs Section */}
        <div className="lg:w-1/3">
          {topFour.length > 0 && (
            <div className="sticky top-0">
              <h2 className="text-2xl font-semibold mb-4">Latest  Blogs</h2>
              <div className="flex flex-col gap-4">
                {topFour.map((relatedBlog) => (
                  <RelatedCard key={relatedBlog._id} blog={relatedBlog} path="blogs"/>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
