import React from "react";
import { Link } from "react-router-dom";

const RelatedCard = ({ blog,path }) => {
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short", // Short month format (e.g., Jan, Feb)
      day: "numeric",
    };

    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const truncateDescription = (description, maxLength = 40) => {
    return description.length > maxLength
      ? description.slice(0, maxLength) + "..."
      : description;
  };

  return (
    <Link to={`/${path}/${blog._id}`} className="max-w-xs bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-200">
      <div className="relative h-32">
        <img
          src={`https://api.safekaro.com/uploads/${blog.image}`}
          alt={blog.title}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Content Section */}
      <div className="px-3 py-3">
        <h3 className="text-base font-semibold text-gray-800 mb-1">
          {truncateDescription(blog.title, 50)}
        </h3>
        <p className="text-xs text-gray-500 mb-1">{formatDate(blog.date)}</p>

        <div className="flex justify-between items-center">
          <span className="text-xs text-gray-500">{blog.category}</span>
        </div>
      </div>
    </Link>
  );
};

export default RelatedCard;
