import React from 'react'
import './home.css'
import homeRightImg from '../../assets/home.svg'
import Hicon1 from '../../assets/Hicon1.svg'
import Hicon2 from '../../assets/Hicon2.svg'
import Hicon3 from '../../assets/Hicon3.svg'
import Hdot1 from '../../assets/Hdot1.svg'
import Hdot2 from '../../assets/Hdot2.svg'
import phone from '../../assets/phone.png'
import arrow from '../../assets/arrow1.png'

const Home = () => {
  return (
    <div className='home-main'>
      <div className='home-left'>
        <div className='home-left-details'>
          
          <h1 className='home-title'>
            Get <span className='about-blue'>Insurance </span> in 2 <span className='about-orange'>Minutes</span>
          </h1>
          <div className='home-container'>
            <div className='container-box'>
              <img src={Hicon3} alt='icon1' className='icon1'/>
              <h3>Fill details</h3>
            </div>
            <span className='arrow'><img src={arrow} alt='arrow' className='right-arrow'/></span>
            <div className='container-box'>
              <img src={Hicon2} alt='icon2'className='icon2'/>
              <h3>Compare Quote & Insurer</h3>
            </div>
            <span className='arrow'><img src={arrow} alt='arrow'className='right-arrow'/></span>
            <div className='container-box'>
              <img src={Hicon1} alt='icon3' className='icon3'/>
              <h3>Make Payment & Relax</h3>
            </div>
          </div>
          <div className='home-button'>
            <button>Get a Quote</button>
            <div className='button2'>
              <a href="#Footer" className='btn2'> <img src={phone} alt='phone'/> Contact Expert</a>
            </div>
          </div>
        </div>
        <div>
          <img src={Hdot1} alt='right-dots' className='right-dot1'/>
        </div>
      </div>
      <div className='home-right'>
        <img src={homeRightImg} alt='right-img' className='homeRightImg'/>
      </div>
      <img src={Hdot2} alt='right-dots' className='right-dot'/>
    </div>
  )
}

export default Home
