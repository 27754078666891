import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getFromSessionStorage } from "../../utils/SessionHandler";
import RelatedCard from "../Blog/RelatedCard";


const NewsDetails = () => {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const [topFour, setTopFour] = useState([]);

  useEffect(() => {
    const data = getFromSessionStorage("news");
    if (data) {
      const newsData = data.filter((ele) => ele._id === id);
      const topNews = data.filter((ele) => ele._id !== id).slice(0, 4);
      setNews(newsData[0]);
      setTopFour(topNews);
    }
  }, [id]);

  if (!news) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto mt-2">
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Main Blog Content */}
        <div className="lg:w-2/3">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <img
              src={`https://api.safekaro.com/uploads/${news.image}`}
              alt={news.title}
              className="w-full h-60 md:h-[30rem] object-cover"
            />
            <div className="p-6">
              <div className="my-1">
                <Link to="/" className="text-blue-500 hover:underline text-lg">
                  Back to Blogs
                </Link>
              </div>
              <h1 className="text-3xl font-bold mb-2">{news.title}</h1>
              <p className="text-gray-500 text-sm mb-4">
                {new Date(news.date).toLocaleDateString()} | By {news.author}
              </p>
              <div
                className="blog-body text-gray-700"
                dangerouslySetInnerHTML={{ __html: news.description }}
              />
            </div>
          </div>
        </div>

        {/* Related Blogs Section */}
        <div className="lg:w-1/3">
          {topFour.length > 0 && (
            <div className="sticky top-0">
              <h2 className="text-2xl font-semibold mb-4">Latest Blogs</h2>
              <div className="flex flex-col gap-4">
                {topFour.map((relatedNews) => (
                  <RelatedCard key={relatedNews._id} blog={relatedNews} path="news" />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
