import React from "react";
import "./whysafekaro.css";
import about2 from "../../assets/about2.svg";
import whySafeKaro from "../../assets/whysafekaro.svg";
import whySafeKaro3 from "../../assets/whysafekaro3.svg";
import whySafeKaro4 from "../../assets/whysafekaro4.svg";
import whySafeKaro5 from "../../assets/whysafekaro5.svg";
import whySafeKaro6 from "../../assets/whysafekaro6.svg";

const WhySafeKaro = () => {
  return (
    <div className="whysafekaro-main">
      <div className="whysafekaro-content">
        <div className="whysafekaro-details">
          <h2 className="about-title">
            <span className="child-one">Why</span><span className="about-blue"> Safe</span>
            <span className="about-orange">Karo</span>
            <img src={about2} alt="about2" className="about2" />
          </h2>
          <h3>
            There's more than one reason to sign up{" "}
            <span className="about-orange">with confidence</span>
          </h3>
          <div className="image-container">
            <img src={whySafeKaro4} alt="whysafekaro4" className="whysafekaro4" />
            <img src={whySafeKaro} alt="whysafekaro" className="whysafekaro" />
            <img src={whySafeKaro3} alt="whysafekaro3" className="whysafekaro3" />
            <img src={whySafeKaro5} alt="whysafekaro5" className="whysafekaro5" />
            <img src={whySafeKaro6} alt="whysafekaro6" className="whysafekaro6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySafeKaro;
