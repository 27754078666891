import React from 'react'
import './services.css'
import motor from '../../assets/services-motor.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faHeartbeat, faCar, faLifeRing, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import about2 from '../../assets/about2.svg'
const Services = () => {
  return (
    <div className='services-main'>
      <div className='services-header'>
        <h2 className='services-title'>Services <img src={about2} alt='about2' className='about2'/></h2>
        <h1 className='services-subline'>What We Can Offer to <span className='about-orange'>Our Clients</span></h1>
        <div className='circle-buttons'>
          <div className='circle-button'>
            <FontAwesomeIcon icon={faHome} />
          </div>
          <div className='circle-button'>
            <FontAwesomeIcon icon={faHeartbeat} />
          </div>
          <div className='circle-button active'>
            <FontAwesomeIcon icon={faCar} />
          </div>
          <div className='circle-button'>
            <FontAwesomeIcon icon={faLifeRing} />
          </div>
          <div className='circle-button'>
            <FontAwesomeIcon icon={faBriefcase} />
          </div>
        </div>
      </div>
      <div className='services-content'>
        <div className='left-image'>
          <img src={motor} alt='motor' className='motorImg'/>
        </div>
        <div className='right-content'>
          <h2>Motor Insurance</h2>
          <p>Motor Insurance is a type of insurance policy which covers your vehicles from potential risks financial security against damages arising out of accidents and other threats.</p>
          <button>Request Services</button>
        </div>
      </div>
    </div>
  )
}

export default Services
