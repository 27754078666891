import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import BlogDetails from "./components/Blog/BlogDetails";
import Website from "./components/Website/Website";
import NotFound from "./components/Notfound/NotFound";
import NewsDetails from "./components/NewsLetter/NewsDetails";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Website />} />
        <Route path="blogs/:id" element={<BlogDetails />} />
        <Route path="news/:id" element={<NewsDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
